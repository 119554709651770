import { Component, OnInit, ElementRef } from '@angular/core';
import { MembershipService } from '@app/xServices/membership.service';
import { environment } from '@environments/environment';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '@app/xServices/token.service';

@Component({
  selector: 'ctg-my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.scss']
})
export class MyNavComponent implements OnInit {
  menuValue:boolean=false;
  menu_icon :string ='bi bi-list';

  public isAuthorized: boolean = false;
  public MyUserName: string = "";
  public inboxCount: number = 0;

  constructor(private activatedRoute: ActivatedRoute, 
    private tokenService: TokenService, 
    private decryptPipe: DecryptPipe, 
    private router: Router,
    private membershipService: MembershipService) {}

  ngOnInit(): void {
    this.SendLogInStatus();
    this.GetInboxCount();
  }

  private SendLogInStatus() {
    this.tokenService.SendLogInStatusEvent
      .subscribe((data: boolean) => {
        this.isAuthorized = data;
        if (data) {
          this.isAuthorized = this.tokenService.IsAuthorized();
        }
      });
  }

  public inLogOut() {
    this.isAuthorized = false;
    this.tokenService.ClearlocalStorage();
    localStorage.removeItem("adamCGUID");
    this.router.navigate(["/home"]);
  }
  

  private async GetInboxCount() {
    this.membershipService.GetInboxCount().subscribe({
      next: (data) => {
        this.inboxCount = data;       
      }
    });
  }

  public openMenu(){
    this.menuValue =! this.menuValue ;
    this.menu_icon = this.menuValue ? 'bi bi-x' : 'bi bi-list';
  }

  public closeMenu() {
    this.menuValue = false;
    this.menu_icon = 'bi bi-list';
  }
}
