
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CrmService } from './crm.service';

@Injectable({
  providedIn: 'root'
})
export class WorkplaceService {

  constructor(private crmService: CrmService, private domSanitizer: DomSanitizer) { }

  public TerminateWorkplace(workplaceId: string) {
    var endPoint = "workplace/terminate/" + workplaceId;
    return this.crmService.patch<any>(endPoint, {});
  }

  public upsertWorkplace(contactId: string, workplaceId: string, name: string, startDate: string, endDate: string) {
    var record: any = {};
    record.ctg_contactId = contactId;
    record.ctg_name = name;
    record.ctg_datefrom = startDate;
    if (workplaceId != "") {
      record.ctg_workplaceId = workplaceId;
    }
    if (endDate) {
      record.ctg_dateto = endDate;
    }

    var endPoint = "workplace/upsert";
    let sendData = JSON.stringify(record);
    return this.crmService.post<any>(endPoint, sendData, false);
  }

};

