import { LogoutComponent } from './logout/logout.component';

import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserContactsComponent } from './user-contacts/user-contacts.component';
import { UserMembershipComponent } from './user-membership/user-membership.component';
import { InvoiceMainComponent } from './invoice/invoice-main/invoice-main.component';
import { BankIdComponent } from './auth/bank-id/bank-id.component';
import { BankIdRedirectComponent } from './auth/bank-id-redirect/bank-id-redirect.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './xGuards/auth.guard';
import { InnerGuard } from './xGuards/Inner.guard';
import { UserMembershipbenefitsComponent } from './user-membershipbenefits/user-membershipbenefits.component';
import { FromcrmComponent } from './auth/fromcrm/fromcrm.component';
import { RegistrationUpdateComponent } from './registration-update/registration-update.component';
import { UserInboxComponent } from './user-inbox/user-inbox.component';
import { UserMyPageComponent } from './user-my-page/user-my-page.component';
import { WelcomeMyPageComponent } from './welcome-to-my-page/welcome-to-my-page.component';
import { UserWorkplaceComponent } from './user-workplace/user-workplace.component';
import { UserEducationComponent } from './user-education/user-education.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { RegistrationResultComponent } from './registration-result/registration-result.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { VippsCaptureComponent } from './auth/vipps-capture/vipps-capture.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: WelcomeMyPageComponent },
  { path: 'welcome', component: WelcomeMyPageComponent },
  { path: 'blimedlem', component: HomeComponent },

  { 
    path: 'registration',  
    children: [
      {
        path: '', // child route path
        component: RegistrationComponent, pathMatch: 'full'//, canActivate: [AuthGuard]  // child route component that the router renders
      },
      {
        path: 'u', // child route path
        component: RegistrationComponent, pathMatch: 'full'//, canActivate: [AuthGuard]  // child route component that the router renders
      },
      {
        path: 'update', // child route path
        component: RegistrationUpdateComponent//, canActivate: [AuthGuard] 
      },
      {
        path: 'result/:parameter', //display message of registration
        component: RegistrationResultComponent
      }
    ]
  },

 
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  
  
  {
    path: 'login',
    children: [
      {
        path: 'fromcrm/:parameter', // child route path
        component: FromcrmComponent // child route component that the router renders
      },
      {
        path: 'bankid/:parameter', // child route path
        component: BankIdComponent // child route component that the router renders
      },
      { path: 'bankid', redirectTo: '', pathMatch: 'full'},
      {
        path: '', // child route path
        component: LoginComponent,pathMatch: 'full'
      },
      {
        path: 'url_redirect', // child route path
        component: BankIdRedirectComponent,  data: { showRootComponents: true } // child route component that the router renders
      }

    ]
  },

  {
    path: 'vipp',
    children: [
      {
        path: 'capture',
        component: VippsCaptureComponent
      },
      { 
        path: 'agreement', 
        component: UserAgreementComponent 
      },
    ]
  },

  { path: 'mypage', component: UserMyPageComponent , canActivate: [AuthGuard] },
  { path: 'inbox', component: UserInboxComponent , canActivate: [AuthGuard] },
  { path: 'membershipbenefits', component: UserMembershipbenefitsComponent , canActivate: [AuthGuard] },
  { path: 'contacts', component: UserContactsComponent , canActivate: [AuthGuard] },
  { path: 'membership', component: UserMembershipComponent , canActivate: [AuthGuard] },
  { path: 'invoice', component: InvoiceMainComponent , canActivate: [AuthGuard] },
  { path: 'memberWorkplace', component: UserWorkplaceComponent , canActivate: [AuthGuard] },
  { path: 'memberEducation', component: UserEducationComponent , canActivate: [AuthGuard] },
  { path: 'resetpassword', component: UserPasswordComponent , canActivate: [AuthGuard] },
  { path: 'resetpassword/:mode', component: UserPasswordComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
