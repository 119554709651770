import { UserContacts } from './../xModels/UserContacts';
import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateUser } from '@app/xModels/RegistrationUser';
import { TokenService } from '@app/xServices/token.service';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { CrmService } from '@app/xServices/crm.service';
import { BaseModel, OptionSetItem } from '@app/xModels/BaseModel';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { HelperService } from '@app/xServices/help.service';
import { DOCUMENT } from '@angular/common';
// import { UserMembershipComponent } from '@app/user-membership/user-membership.component';


@Component({
  selector: 'ctg-user-contacts',
  templateUrl: './user-contacts.component.html',
  styleUrls: ['./user-contacts.component.scss'],

})
export class UserContactsComponent implements OnInit {

  editMode: boolean = false;
  public updateContactForm!: UntypedFormGroup;
  public userContactInfo = new UserContacts();
  private UpdateUser: UpdateUser | undefined;
  // private personalCGUID: string = "";  // personal CRM ID GUID
  editModeContactInfo: boolean = false;
  public saveDataButton: boolean = false;
  public MembershipCurrentList: IPicklistCategories[] = [];
  private userId: string = "";
  public testView: UserContacts = new UserContacts();
  public invoiceOption: OptionSetItem[] = [];

  // @Select(UserInfoState) UserState$?: Observable<UserContacts>;

  constructor(@Inject(DOCUMENT) private document: Document, 
  private userService: UserService, 
  private spinner: NgxSpinnerService, 
  private router: Router,
    
    public fb: UntypedFormBuilder, private crmService: CrmService, private tokenService: TokenService, private storeManageService: StoreManageService,private helperService: HelperService) {
    this.spinner.show();

    if (this.helperService.isEmptyOrSpaces(this.userId))   this.userId = this.storeManageService.GetContactId(); 
    this.userService.getInvoiceOption().subscribe(res =>
      {
        this.invoiceOption = res;
      });
  }

  ngOnInit(): void {

    this.updateContactForm = this.fb.group({
      city: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,28}$/)]],
      zipCode: ['', [Validators.required, Validators.pattern(/^\s?\S+[a-zA-Z0-9_-\s+]{2,9}$/)]], //  length from 3 to 9 characters],
      locationOrStreetName: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,28}$/)]], //  start with letter[length from 4 to 28 characters]], old RegExp --> (/^\s?\S+[a-zA-Z0-9_./-\s+]{1,28}$/)
      userEmail: ['', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)]],
      sendInvoiceVia:  [961700002, Validators.required],
      membershipCurrent: [''],
    });

    /*let userDataStore = this.storeManageService.GetUserData();
    if (!this.helperService.isEmptyOrSpaces(userDataStore.contactid)) {
      this.ComponentFormFill(userDataStore);
    } else {
      this.ContactsInitialization(this.userId);
    }*/

      //PAKPOOM - REMOVE THIS to reuse above
      this.ContactsInitialization(this.userId);
  }

  public labelOfMedlemI(id: number | undefined) {
    if (id) {
      return this.MembershipCurrentList.find(e => e.value == id)?.label;
    }
    return "";
  }


  private ContactsInitialization(userCrmId: string) {

    this.spinner.show();
    this.userService.GetUserInfo(userCrmId).subscribe({
      next: (value: BaseModel<UserContacts>) => {
        this.userContactInfo = value.responseData;
        this.tokenService.ResetContactStorage();
        this.tokenService.SetToken(value.responseData.accessToken);

        const contactId = this.tokenService.GetIdentity().ContactId;
        this.storeManageService.AddContactId(contactId);
        //this.tokenService.SetToken(this.userContactInfo.accessToken);
        value.responseData.accessToken="";
        this.ComponentFormFill(value.responseData);
      },
      error: (err) => {
        console.error("errorMessage: " + err);
        this.spinner.hide();
      },
      complete: () => {
        this.storeManageService.AddNewUserInfoState(this.userContactInfo);
        this.storeManageService.ResetState('contactId'); 
        //  this.store.dispatch(new AddUserInfoAction(this.userContactInfo));
        this.spinner.hide();
      }

    });

  }


  private ComponentFormFill(userInfoData: UserContacts){
    this.userContactInfo = userInfoData;
    if (!!this.userContactInfo.address1_city)  this.updateContactForm.get("city")?.setValue(userInfoData.address1_city);
    if (!!this.userContactInfo.address1_postalcode) this.updateContactForm.get("zipCode")?.setValue(userInfoData.address1_postalcode);
    if (!!this.userContactInfo.address1_line1)  this.updateContactForm.get("locationOrStreetName")?.setValue(userInfoData.address1_line1);
    if (!!this.userContactInfo.sendInvoiceVia) this.updateContactForm.get("sendInvoiceVia")?.setValue(userInfoData.sendInvoiceVia);
    this.updateContactForm.get("userEmail")?.setValue(userInfoData.emailaddress1);  
  }

  /**
   * editModeContact
  */
  public editModeContact() {
    this.editModeContactInfo=true;   
      this.crmService.GetPicklistValues("contact", "ctg_medlemi").subscribe({
        next: (data: IPicklistCategories[]) => {
          this.updateContactForm!.get('membershipCurrent')!.enable();
          this.MembershipCurrentList = data;
        }
      }); 
    
  }

  preVerifyOnSave(sendInvoiceVia: any): boolean {
    // If previous one is Vipp - Confirm to deactivate agreement
    if (this.userContactInfo.sendInvoiceVia == 961700004 && this.userContactInfo.sendInvoiceVia != parseInt(sendInvoiceVia)) {
      if(!confirm("For å endre faktura via Vipps til et annet alternativ, må vi kansellere denne avtalen. Vennligst bekreft ?")) {
        return false;
      }
    }
    else if (this.userContactInfo.sendInvoiceVia != 961700004 && 961700004 == parseInt(sendInvoiceVia))
    {
      if(!confirm("For å bruke faktura via Vipps, vil vi omdirigere deg til å fortsette prosessen på Vipps. Vennligst følg trinnet og fullfør avtalen ?")) {
        return false;
      }
    }

    return true;
  }
  onSaveDataContactInfo(): void {

    this.saveDataButton = true;

    if (this.UpdateUser == undefined) {
      this.UpdateUser = new UpdateUser();
    }
    this.spinner.show();
    this.UpdateUser.Merge(this.userContactInfo);
    let formEmail = this.updateContactForm.value.userEmail;
    let formZip = this.updateContactForm.value.zipCode.toString();
   
    let formAddress = this.updateContactForm.value.locationOrStreetName;
    let formCity = this.updateContactForm.value.city;
    let sendInvoiceVia = this.updateContactForm.value.sendInvoiceVia;

    if (!this.preVerifyOnSave(sendInvoiceVia)) {
      this.saveDataButton = false;
      this.updateContactForm.get("sendInvoiceVia")?.setValue(this.userContactInfo.sendInvoiceVia);
      this.spinner.hide();
      return;
    }

    if (formEmail != this.userContactInfo.emailaddress1) {
      this.UpdateUser.emailaddress1 = this.updateContactForm.value.userEmail;
    }
   
    if (formZip != this.userContactInfo.address1_postalcode && formZip) {
      this.UpdateUser.address1_postalcode = this.updateContactForm.value.zipCode.toString();
    }

    if (formAddress != this.userContactInfo.address1_line1 && formAddress) {
      this.UpdateUser.address1_line1 = this.updateContactForm.value.locationOrStreetName;
    }    
    
    if (formCity != this.userContactInfo.address1_city && formCity) {
      this.UpdateUser.address1_city = this.updateContactForm.value.city;
    }  

    if (sendInvoiceVia != this.userContactInfo.sendInvoiceVia && sendInvoiceVia) {
      this.UpdateUser.sendInvoiceVia = this.updateContactForm.value.sendInvoiceVia;
    }  

    this.userService.UpdateContactInfo(this.UpdateUser).subscribe({
      next: (res) => {
        if (res && res.responseData && res.responseData.requireToRedirect && res.responseData.redirectUrl) {
          this.ContactsInitialization(this.userId);
          this.spinner.hide();
          this.document.location.href = res.responseData.redirectUrl;
        }
        else {
          this.ContactsInitialization(this.userId);
          /*this.saveDataButton = false;
          this.spinner.hide();
          this.editModeContactInfo = false;*/
        }

      },
      error: (err) => {

        this.saveDataButton = true;
        this.spinner.hide();
        this.editModeContactInfo = false;
        console.error("errorMessage: " + err);

      },
      complete: () => {
        this.saveDataButton = false;
        this.spinner.hide();
        this.editModeContactInfo = false;
      },
    });

  }

  displayInvoiceLabel(val: number) {
    return this.invoiceOption.find(a => a.value == val)?.label ?? '';
  }

  continueVippsCreateVippsAgreement() {

  }
}
