import { UserService } from '@app/xServices/user.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RequestNewMembership, UserMembership } from '../xModels/UserMembership';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMembership, Membership } from '@app/xModels/Membership';
import { MembershipService } from '@app/xServices/membership.service';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { CrmService } from '@app/xServices/crm.service';
import * as moment from 'moment';
import { BaseModel } from '@app/xModels/BaseModel';
import { StoreManageService } from '@app/xServices/StoreManageService.service';


@Component({
  selector: 'ctg-user-membership',
  templateUrl: './user-membership.component.html',
  styleUrls: ['./user-membership.component.scss']
})

export class UserMembershipComponent implements OnInit {

  @Input() ContactId: string = "";
  @Output() sendEmployerPlaceNameEvent: EventEmitter<string> = new EventEmitter<string>();


  public editOrCreate: boolean = true;
  public userMembershipInfo: UserMembership[] = [];
  public endMembershipReasons: IPicklistCategories[] = [];
  public updateMembershipForm!: UntypedFormGroup;
  public editModeMembership: boolean = false;
  public endMembership: boolean = false;
  public editMembership: boolean = false;
  public createNewWorkplaceForm!: UntypedFormGroup;
  public isCurrentMembership: boolean = false;
  public isEnableToTerminate: boolean = false;
  public btnSave: boolean = false;
 
  //public CurrentMembershipBinding: boolean = false;

  public MembershipList: Membership[] = [];
  private TermindateMemberShipId: string = '';

  public showHistoryMembership = false;
  public showHistory = false;
  public btnReasonOther: boolean = false;
  public btnEnd: boolean = false;

  public userMembershipCurrent: UserMembership = new UserMembership;
  public userMembershipUpcoming: UserMembership[] = [];
  public userMembershipOld: UserMembership[] = [];

  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public fb: UntypedFormBuilder,
    private crmService: CrmService,
    private storeManageService:StoreManageService) {

    //this.MockMembershipList();

    //this.getEndMembershipReasons();

  }

  ngOnInit(): void {
    this.spinner.show();
    this.MembershipInitialization();
    this.updateMembershipForm = this.fb.group({
      membershipType: new FormControl(''),
      endMembershipReason: this.fb.array([]),
      endMembershipReasonText: new FormControl('')
    });

    this.createNewWorkplaceForm = this.fb.group({
      employer: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,50}$/)]], //  start with letter[length from 2 to 50 characters]],\\
      employerType: ['', [Validators.required]], //  start with letter[length from 2 to 50 characters]],\\
    });

    // Subscription when membership on terminate process
    this.updateMembershipForm.controls['endMembershipReasonText'].valueChanges.subscribe((value:any) => {
      var isSpecifyReasonCheck = this.updateMembershipForm.get("reason-961700007")?.value;
      if (isSpecifyReasonCheck) {
        if (value != '') {
          this.btnEnd = true;
        }
        else {
          this.btnEnd = false;
        }
      }
    });

    this.GetCurentMembership();
    this.spinner.hide();
  }

  private getEndMembershipReasons() {
    this.crmService.GetPicklistValues("ctg_membership", "ctg_reasonforcancellation", true).subscribe({
      next: (data: IPicklistCategories[]) => {
        this.endMembershipReasons = data;
        data.forEach(a => {
          this.updateMembershipForm.addControl('reason-' + a.value.toString(), new FormControl(''));
        });
            
        this.spinner.hide();
      }
    });
  }
  
  public onSelectEditMembershipChange(vareidSelected: any) {
    if (!!vareidSelected.value) {
      this.btnEnd=true;
    }
  }

  public onEndMembershipAct() {
    this.TermindateMemberShipId = this.userMembershipCurrent.id;

    this.editModeMembership = true;
    this.editMembership = false;
    this.endMembership = true;

    // Reset choice
    this.endMembershipReasons.forEach(a => {
      this.updateMembershipForm.get("reason-"+ a.value.toString())?.setValue(false);
    });
  };

  public onEndUpcommingMembership(memberShipId: string) {
    this.TermindateMemberShipId = memberShipId;

    this.editModeMembership = true;
    this.editMembership = false;
    this.endMembership = true;

    // Reset choice
    this.endMembershipReasons.forEach(a => {
      this.updateMembershipForm.get("reason-"+ a.value.toString())?.setValue(false);
    });
  }

  public onActionMembershipAct() {
    this.editModeMembership = true;
    this.editMembership = true;
    this.endMembership = false;
    this.TermindateMemberShipId = "";
  };

  private MembershipInitialization() {
    var userId = this.storeManageService.GetContactId();

    this.membershipService.GetAllMembershipByContactId(this.ContactId).subscribe({
      next: (baseData) => {
        let data = baseData.responseData
        this.userMembershipInfo = data;

        // this.endMembership = this.filterCurrentMembership()?.current!;
        this.GetCurentMembership(data);
      },
      error: (err) => {
        this.spinner.hide();
        console.error("errorMessage: " + err);
        this.router.navigate(['/registration']);
      }
    });
  }

  private MockMembershipList() {
    this.userService.GetMembershipList("showonchange").subscribe({
      next: (value: BaseModel<IMembership[]>) => {
        if (this.updateMembershipForm && this.updateMembershipForm.get('membershipType') != null) {
          this.updateMembershipForm.get('membershipType')!.enable();
        }
        this.MembershipList = value.responseData;
      },
      error: (err) => {
        this.spinner.hide();
        this.updateMembershipForm!.get('membershipType')!.disable();
        console.error("errorMessage: " + err);
      }
    });
  }

  public onCancelDataMembership() {
    this.editModeMembership = false;
    this.TermindateMemberShipId = "";
  }

  public onSaveDataMembership() {
    this.spinner.show();

    //CREATE
    if (!this.isCurrentMembership && this.TermindateMemberShipId == "") {
      let lastDateInArray: UserMembership = new UserMembership();
      let newMembership: string = this.updateMembershipForm.value.membershipType;

      try {
        if (this.userMembershipUpcoming != null && this.userMembershipUpcoming !== undefined) {
          lastDateInArray = this.userMembershipUpcoming.reduce((a, b) => (a.memberTo > b.memberTo ? a : b));
        }
        else {
          lastDateInArray = this.userMembershipOld.reduce((a, b) => (a.memberTo > b.memberTo ? a : b));
        }

      } catch (error) {
        lastDateInArray.memberTo = moment().toISOString();
      }

      let newDate = this.newAddMembershipDate(lastDateInArray!.memberTo);

      this.createNewMembership(newMembership, newDate, this.ContactId);
      this.GetCurentMembership();
      return;
    }

    // If Terminate or cancel or stop
    if (this.TermindateMemberShipId != "") { //this.isCurrentMembership && this.endMembership) {
      //End membership
      let idx = this.userMembershipInfo.findIndex(a => a.id == this.TermindateMemberShipId);
      if (idx >= 0) {
        var SelectMembershipObj = this.userMembershipInfo[idx];
        if (SelectMembershipObj.invoiceStartDate) {
          SelectMembershipObj.memberTo = this.newEndDate(SelectMembershipObj.invoiceStartDate).toISOString();
        }
        else {
          SelectMembershipObj.memberTo = moment().toISOString();
        }
  
        let reasons: number = 0;
        this.endMembershipReasons.forEach(a => {
          if (this.updateMembershipForm.get("reason-"+ a.value.toString())?.getRawValue()) {
            reasons = a.value;
          }
        });
        
        let reasonText: string = this.updateMembershipForm.value.endMembershipReasonText;
        this.membershipService.TerminateMembership(SelectMembershipObj, this.ContactId, reasons, reasonText).subscribe({
          next: () => {
            this.MembershipInitialization();
            this.editModeMembership = false;
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 1200);
          },
          error: (err: any) => {
            this.spinner.hide();
            console.error("End membership errorMessage: " + err);
          }
  
        });
      }

      return;
    } 
    // Just update
    else {
      //EDIT 
      let newMembership: string = this.updateMembershipForm.value.membershipType;
      let _endDate = this.GetEndDato();
      if (this.userMembershipCurrent && this.userMembershipCurrent.invoiceStartDate){
        _endDate = this.newEndDate(this.userMembershipCurrent.invoiceStartDate).toISOString();
      }
      this.userMembershipCurrent.memberTo = _endDate;

      let newStartDate = this.newAddMembershipDate(this.userMembershipCurrent.memberTo);
      var requestMembership: RequestNewMembership = {
        membershipId: newMembership,
        fromDate: newStartDate.toISOString(),
        contactId: this.ContactId,
        invoiceStartDate: newStartDate.toISOString(),
      };
      this.membershipService.changeMembership(this.userMembershipCurrent, requestMembership).subscribe({
        next: () => {
          this.MembershipInitialization();
          this.editModeMembership = false;
          setTimeout(() => {
            this.spinner.hide();
          }, 1200);
        },
        error: (err: any) => {
          this.spinner.hide();
          console.error("errorMessage: " + err);
        }
      });
    }
  }

  private createNewMembership(memberShipId: string, startDate: Date, contactId: string) {
    const input = document.getElementById('btnSaveMembership') as HTMLInputElement;

    this.membershipService.createNewMembership(memberShipId, startDate, contactId).subscribe({
      next: () => {
        input.disabled = false;
      },
      error: (err: any) => {
        this.spinner.hide();
        input.disabled = false;
        console.error("errorMessage: " + err);
      },
      complete: () => {
        this.MembershipInitialization();
        this.editModeMembership = false;
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 1200);
      },
    });
  }

  public onChangeReason(event: any, index: number) {
    const formArray: FormArray = this.updateMembershipForm.value as FormArray;
    //Reset another choice
    this.endMembershipReasons.forEach(a => {
      if (a.value != event.target.value) {
        this.updateMembershipForm.get("reason-"+ a.value.toString())?.setValue(false);
      }
    });
    
    //
    /*if (event?.target?.checked) {
      formArray.push(new FormControl(event.target.value));
    }
    else {
      let i: number = 0;
      formArray.controls.forEach((ctrl: any) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }*/
    
    if (event.target.value == '961700007' && event?.target?.checked) {
      this.btnReasonOther = true;
    } else {
      this.btnReasonOther = false;
      this.updateMembershipForm.get('endMembershipReasonText')?.setValue('');
    }

    if (event.target.value && event.target.value != '961700007') {
      this.btnEnd = true;
    }
    else {
      this.btnEnd = false;
    }
    //if (this.updateMembershipForm.value.endMembershipReason.map((checked: any, i: any) => checked ? 1 : null)
    //  .filter(v => v !== null);
    //this.updateMembershipForm.value.endMembershipReason.controls.map((checked, i) => checked ? 1 : null).filter(v => v !== null).length
    //if (formArray.controls.map((checked, i) => checked ? 1 : null).filter(v => v !== null).length > 0) {
    /*  if (this.updateMembershipForm.value.endMembershipReason.controls.map((checked, i) => checked ? 1 : null).filter(v => v !== null).length > 0)
      this.btnEnd = true;
    }
    else {
      this.btnEnd = false;
    }*/
    //Sets btnReasonOther to true/false - and removes text in textfield
    /*if ((this.updateMembershipForm.value.endMembershipReason).length > 0) {
      this.btnEnd = true;
      if ((this.updateMembershipForm.value.endMembershipReason).indexOf('961700007') > -1) {
        this.btnReasonOther = true;
      } else {
        this.btnReasonOther = false;
        this.updateMembershipForm.value.endMembershipReasonText = '';
      }
    } 
    else {
      this.btnReasonOther = false;
      this.btnEnd = false;
    }*/
  }

  private GetCurentMembership(_data?: UserMembership[]): boolean {
    if (_data != null || _data != undefined) {
      this.userMembershipInfo = _data;
      this.isCurrentMembership = (_data.filter(e => e.current == true).length > 0);
      this.userMembershipCurrent = _data.filter(e => e.current == true)[0];
      this.userMembershipUpcoming = _data.filter(e => e.upcoming == true);
      this.userMembershipOld = _data.filter(e => e.upcoming == false && e.current == false);
      console.log("Current MEM" +JSON.stringify(this.userMembershipCurrent));
      console.log("Upcoming MEM" +JSON.stringify(this.userMembershipUpcoming));

    }
    else {
      this.isCurrentMembership = (this.userMembershipInfo.filter(e => e.current == true).length > 0);
      this.userMembershipCurrent = this.userMembershipInfo.filter(e => e.current == true)[0];
      this.userMembershipUpcoming = this.userMembershipInfo.filter(e => e.upcoming == true);
      this.userMembershipOld = this.userMembershipInfo.filter(e => e.upcoming == false && e.current == false);
    }

    this.isEnableToTerminate = this.userMembershipCurrent && this.userMembershipCurrent.memberTo == null;
    this.editOrCreate = this.isCurrentMembership;

    //! Sort
    this.userMembershipUpcoming = this.userMembershipUpcoming.sort((a, b) => Date.parse(b.memberTo) - Date.parse(a.memberTo));
    this.userMembershipOld = this.userMembershipOld.sort((a, b) => Date.parse(b.memberTo) - Date.parse(a.memberTo));

    return (this.isCurrentMembership == undefined);
  }


  public filterCurrentMembership(): UserMembership | undefined {
    console.info("filter: " + this.userMembershipInfo.find(e => e.current == true));
    return this.userMembershipInfo.filter(e => e.current == true)[0];
  }
  public filterUpcomingMembership() {
    return this.userMembershipInfo.filter(e => e.upcoming == true);
  }
  public filterOldMemberships() {
    return this.userMembershipInfo.filter(e => e.upcoming != true && e.current != true);
  }


  /**
   * newAddMemebershipDate
   */
  public newAddMembershipDate(nowDate: string): Date {
    let startDate = moment();
    if (nowDate !== null && nowDate !== undefined) {
      startDate = moment(nowDate).isSameOrAfter() ? moment(nowDate).add(1, 'days') : moment();
    }
    return startDate.toDate();
  }

  /**
   * newEndDate
   * SandBox :   https://codesandbox.io/s/format-date-with-moment-forked-7b0ori?file=/src/index.js
   */
  public newEndDate(dateAsISOString: string): Date {
    let endDate = moment();
    let processedData = moment(dateAsISOString);

    // day  number of start date of membership
    _days = processedData.date();

    const dateIsBefore = moment(processedData).isSameOrBefore();
    if (dateIsBefore) {
      // if it is greater then show difference in months as number
      var _duration = moment.duration(endDate.diff(processedData));
      console.log("Day of memberFrom " + _days);
      let howManyDays = _days - 1 - moment().date();
      console.log("howManyDays memberFrom - today  " + howManyDays);
      if (howManyDays >= 0) {
        endDate = moment().add(howManyDays, "days");
      } else {
        endDate = moment().add(1, "M").set("date", _days).subtract(1, "day");
      }
      var _days = _duration.asDays();
    }
    else {
      endDate = processedData;
    }

    return endDate.toDate();
  }

  /**
 * GetEndDato
 */
  private GetEndDato(): string {
    var endDate = new Date();
    this.GetCurentMembership();
    let nuleDate: string = new Date().toISOString();

    if (this.userMembershipCurrent == null && this.userMembershipCurrent == undefined) {
      if (this.userMembershipUpcoming != null && this.userMembershipUpcoming !== undefined) {
        nuleDate = this.userMembershipUpcoming[0].memberTo;
        endDate = new Date(nuleDate);
        // let _memberTo: Date =  new Date(userMembershipCurrent.memberTo);
        endDate.setDate(endDate.getDate() + 1);
      }
    } else {
      nuleDate = this.userMembershipCurrent.memberFrom;

      var startDate = new Date(nuleDate);
      var daysUntil = (startDate.getDate() - endDate.getDate());

      var daysMAx = startDate > endDate;
      if (daysUntil > 1) {
        endDate = new Date(endDate.setDate(startDate.getDate() - 1));
      } else if (daysUntil < 1 && !daysMAx) {
        endDate = new Date(endDate.setMonth(new Date().getMonth() + 1));
        endDate = new Date(endDate.setDate(startDate.getDate() - 1));
      } else {
        endDate = new Date(this.userMembershipCurrent.memberFrom);
      }
    }
    return endDate.toISOString();
  }
}

